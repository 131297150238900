import Nav from '../../components/Nav/Nav';
import Note from '../../components/Note/Note';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import games from '../../classes/games';
import gameNote from '../../classes/gameNote';

export default function SpecificGame() {
  const [gameData, setGameData] = useState<gameNote>(
    new gameNote('Undefined', -1, null, 'https://www.trueachievements.com', 'Undefined', false, false, false, false, ['Undefined'])
  );

  const params = useParams();
  const gamesList = new games().gamesList;

  useEffect(() => {
    const query = params.game;

    for (let i = 0; i < gamesList.length; i++) {
      if (gamesList[i].gameID === query) {
        loadGameData(gamesList[i]);
        break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.game]);

  const loadGameData = (game: gameNote) => {
    setGameData(game);
    console.log(game);
  };

  return (
    <>
      <Nav />
      <div className="app">
        <div className="content">
          <div className="allNotes">
            <h2>Completion Notes for: {gameData.getGameTitle()}</h2>
            <table className="notesTable">
              <th className="gameHeader">Game</th>
              <th>Completion Difficulty (?/5)</th>
              <th>Tags</th>
              <Note
                gameTitle={gameData.getGameTitle()}
                gameDifficulty={gameData.getGameDifficulty()}
                gamePhoto={gameData.gamePhoto}
                taLink={gameData.getTALink()}
                isAvoid={gameData.getIsAvoid()}
                isUnobtainable={gameData.getIsUnobtain()}
                hasAccessibility={gameData.getHasAccessibility()}
                hasCC={gameData.getHasCC()}
                notes={gameData.getNotes()}
                defaultState={true}
              />
            </table>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
