import Nav from "../../components/Nav/Nav";

export default function About() {
  return (
    <>
      <Nav />
      <div>About</div>
    </>
  );
}
