import Nav from "../../components/Nav/Nav";

export default function FullList() {
  return (
    <>
      <Nav />
      <div>Full List</div>
    </>
  );
}
