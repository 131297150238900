export default class gameNote {
  gameTitle: string;
  gameDifficulty: number;
  gamePhoto: string;
  taLink: string;
  gameID: string;
  isAvoid: boolean;
  isUnobtainable: boolean;
  hasAccessibility: boolean;
  hasCC: boolean;
  notes: string[];

  constructor(
    _gt: string,
    _gd: number,
    _gp: string | null,
    _taLink: string,
    _gameID: string,
    _isAvoid: boolean,
    _isUnobtain: boolean,
    _hasAccessibility: boolean,
    _hasCC: boolean,
    _notes: string[]
  ) {
    this.gameTitle = _gt;
    this.gameDifficulty = _gd;

    if (_gp === null) {
      this.gamePhoto = 'https://placehold.co/50x50';
    } else {
      this.gamePhoto = _gp;
    }

    this.taLink = _taLink;
    this.gameID = _gameID;
    this.isAvoid = _isAvoid;
    this.isUnobtainable = _isUnobtain;
    this.hasAccessibility = _hasAccessibility;
    this.hasCC = _hasCC;
    this.notes = _notes;
  }

  getGameTitle(): string {
    return this.gameTitle;
  }

  getGameDifficulty(): number {
    return this.gameDifficulty;
  }

  getTALink(): string {
    return this.taLink;
  }

  getGameID(): string {
    return this.gameID;
  }

  getIsAvoid(): boolean {
    return this.isAvoid;
  }

  getIsUnobtain(): boolean {
    return this.isUnobtainable;
  }

  getHasAccessibility(): boolean {
    return this.hasAccessibility;
  }

  getHasCC(): boolean {
    return this.hasCC;
  }

  getNotes(): string[] {
    return this.notes;
  }
}
