import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import FullList from './pages/Full List/FullList';
import SpecificGame from './pages/SpecificGame/SpecificGame';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/full-list" element={<FullList />} />
        <Route path="/:game" element={<SpecificGame />} />
      </Routes>
    </Router>
  );
}
