import './Home.css';
import { useEffect, useState } from 'react';
// import { ChevronDown, ChevronsLeft, ChevronsRight } from 'react-feather';
import Nav from '../../components/Nav/Nav';
import Note from '../../components/Note/Note';
import games from '../../classes/games';
import gameNote from '../../classes/gameNote';
// import { reject, cloneDeep } from 'lodash';
import { cloneDeep } from 'lodash';

const gl = new games();

export default function Home() {
  const sortList = (_list: gameNote[]) => {
    return _list.sort((a, b) => a.gameTitle.localeCompare(b.gameTitle));
  };

  const [activeList, setActiveList] = useState(gl.gamesList);
  const [searchQuery, setSearchQuery] = useState('');
  // const [showNavigation, setShowNavigation] = useState(false);
  // const [showFilters, setShowFilters] = useState(true);
  // const [showAvoid, setShowAvoid] = useState(true);
  // const [showUnobtain, setShowUnobtain] = useState(true);
  const [showMostRecent, setShowMostRecent] = useState(true);
  // const [showDiffOne, setShowDiffOne] = useState(true);
  // const [showDiffTwo, setShowDiffTwo] = useState(true);
  // const [showDiffThree, setShowDiffThree] = useState(true);
  // const [showDiffFour, setShowDiffFour] = useState(true);
  // const [showDiffFive, setShowDiffFive] = useState(true);

  const currentQuery = (query: string) => {
    setSearchQuery(query);
  };

  const updateMostRecent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowMostRecent(e.target.checked);
  };

  // const updateDiffOne = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setShowDiffOne(e.target.checked);
  // };

  // const updateDiffTwo = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setShowDiffTwo(e.target.checked);
  // };

  // const updateDiffThree = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setShowDiffThree(e.target.checked);
  // };

  // const updateDiffFour = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setShowDiffFour(e.target.checked);
  // };

  // const updateDiffFive = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setShowDiffFive(e.target.checked);
  // };

  // const updateAvoid = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setShowAvoid(e.target.checked);
  // };

  // const updateUnobtain = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setShowUnobtain(e.target.checked);
  // };

  useEffect(() => {
    let _list: gameNote[] = cloneDeep(gl.gamesList);

    if (!showMostRecent) _list = sortList(_list);

    // if (!showAvoid) _list = reject(_list, 'isAvoid');
    // if (!showUnobtain) _list = reject(_list, 'isUnobtainable');

    // if (!showDiffOne) _list = reject(_list, { gameDifficulty: 1 });
    // if (!showDiffTwo) _list = reject(_list, { gameDifficulty: 2 });
    // if (!showDiffThree) _list = reject(_list, { gameDifficulty: 3 });
    // if (!showDiffFour) _list = reject(_list, { gameDifficulty: 3 });
    // if (!showDiffFive) _list = reject(_list, { gameDifficulty: 3 });

    if (searchQuery) _list = _list.filter((gameNote) => gameNote.gameTitle.toLowerCase().includes(searchQuery.toLowerCase()));

    // Removes duplicates
    _list = _list.filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });

    setActiveList(_list);
    // }, [showMostRecent, showAvoid, showUnobtain, showDiffOne, showDiffTwo, showDiffThree, showDiffFour, showDiffFive, searchQuery]);
  }, [showMostRecent, searchQuery]);

  // const toggleFilters = () => {
  //   setShowFilters((showFilters) => !showFilters);
  // };

  // const toggleNavigation = () => {
  //   setShowNavigation((showNavigation) => !showNavigation);
  // };

  return (
    <>
      <Nav />
      <div className="app">
        <div className="filterContainer">
          <table className="filters">
            <tr>
              <td>
                <div className="filterDetail">
                  <b>Search:</b>
                </div>
              </td>
              <td>
                <input className="searchNotes" onChange={(e) => currentQuery(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td>
                <div className="filterDetail">
                  <b>Order by Most Recent:</b>
                </div>
              </td>
              <td className="filterDetail">
                <input type="checkbox" checked={showMostRecent} onChange={(e) => updateMostRecent(e)} />
              </td>
            </tr>
          </table>
        </div>
        <div className="content">
          {/* {showNavigation ? (
            <div className="searchContent">
              <div className="searchContainer">
                <div className="collapse">
                  {showNavigation ? (
                    <ChevronsLeft className="collapseIcon" onClick={() => toggleNavigation()} size={30} />
                  ) : (
                    <ChevronsRight className="collapseIcon" onClick={() => toggleNavigation()} size={30} />
                  )}
                </div>
                <div className="searchHeader">
                  <b>Search:</b>
                </div>
                <input className="searchNotes" onChange={(e) => currentQuery(e.target.value)} />
                <hr />
                <div className="filter" onClick={() => toggleFilters()}>
                  <b className="filterTitle">Filters</b>
                  <ChevronDown size={22} />
                </div>
                {showFilters ? (
                  <table>
                    <tr>
                      <td>Order by Most Recent</td>
                      <td>
                        <input type="checkbox" checked={showMostRecent} onChange={(e) => updateMostRecent(e)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Show Difficulty 1</td>
                      <td>
                        <input type="checkbox" checked={showDiffOne} onChange={(e) => updateDiffOne(e)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Show Difficulty 2</td>
                      <td>
                        <input type="checkbox" checked={showDiffTwo} onChange={(e) => updateDiffTwo(e)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Show Difficulty 3</td>
                      <td>
                        <input type="checkbox" checked={showDiffThree} onChange={(e) => updateDiffThree(e)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Show Difficulty 4</td>
                      <td>
                        <input type="checkbox" checked={showDiffFour} onChange={(e) => updateDiffFour(e)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Show Difficulty 5</td>
                      <td>
                        <input type="checkbox" checked={showDiffFive} onChange={(e) => updateDiffFive(e)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Show Avoid</td>
                      <td>
                        <input type="checkbox" checked={showAvoid} onChange={(e) => updateAvoid(e)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Show Unobtainables</td>
                      <td>
                        <input type="checkbox" checked={showUnobtain} onChange={(e) => updateUnobtain(e)} />
                      </td>
                    </tr>
                  </table>
                ) : null}
              </div>
            </div>
          ) : (
            <div className="collapseContent">
              <ChevronsRight className="collapseIcon" onClick={() => toggleNavigation()} size={30} />
            </div>
          )} */}

          {/* <input onChange={(e) => currentQuery(e.target.value)} /> */}
          <div className="allNotes">
            <table className="notesTable">
              <colgroup>
                <col span={1} style={{ width: 50 }} />
                <col span={1} style={{ width: 20 }} />
                <col span={1} style={{ width: 20 }} />
              </colgroup>
              <th className="gameHeader">Game</th>
              <th className="completionHeader">Completion Difficulty (?/5)</th>
              <th>Tags</th>
              {activeList.map((game) => (
                <Note
                  gameTitle={game.getGameTitle()}
                  gameDifficulty={game.getGameDifficulty()}
                  gamePhoto={game.gamePhoto}
                  taLink={game.getTALink()}
                  isAvoid={game.getIsAvoid()}
                  isUnobtainable={game.getIsUnobtain()}
                  hasAccessibility={game.getHasAccessibility()}
                  hasCC={game.getHasCC()}
                  notes={game.getNotes()}
                  defaultState={false}
                />
              ))}
            </table>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
