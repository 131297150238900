import { ExternalLink } from 'react-feather';
import { ArrowUpRight, ChevronDown } from 'react-feather';
import { useState } from 'react';
import './Note.css';

interface iNote {
  gameTitle: string;
  gameDifficulty: number;
  gamePhoto: string;
  taLink: string;
  isAvoid: boolean;
  isUnobtainable: boolean;
  hasAccessibility: boolean;
  hasCC: boolean;
  notes: string[];
  defaultState: boolean;
}

export default function Note({
  gameTitle,
  gameDifficulty,
  gamePhoto,
  taLink,
  isAvoid,
  isUnobtainable,
  hasAccessibility,
  hasCC,
  notes,
  defaultState,
}: iNote) {
  const [showNote, setShowNote] = useState(defaultState);

  const toggleNote = () => {
    setShowNote((showNote) => !showNote);
  };

  return (
    <>
      <tr className="tableRowGameData">
        <td className="gameTitleContainer">
          <img className="gameImg" src={gamePhoto} height={50} width={50} alt="Icon" />
          <div className="gameTitle noselect" onClick={() => toggleNote()}>
            {gameTitle}
          </div>
          <ChevronDown className="chevronDown" size={22} onClick={() => toggleNote()} />
        </td>
        <td className="gameDifficulty">
          <b>{gameDifficulty}</b>
        </td>
        <td className="tags">
          <div className="tagsContainer">
            {isAvoid ? <span className="avoid">Avoid</span> : null}
            {isUnobtainable ? <span className="unobtainable">Unobtainables</span> : null}
            {hasAccessibility ? <span className="accessibility">Accessibility Options</span> : null}
            {hasCC ? <span className="consoleCommands">Console Commands</span> : null}
          </div>
        </td>
      </tr>
      {showNote ? (
        <span className="noteContentContainer">
          <tr className="noteWrapper">
            <td colSpan={6}>
              <div className="taGamePage">
                <a href={taLink} target="_blank" rel="noopener noreferrer">
                  <div className="gameDetails">
                    <i>TrueAchievements</i>
                    <ExternalLink size={16} className="externalTAPage" />
                  </div>
                </a>
              </div>
              <ul className="noteContent">
                {notes.map((note) =>
                  note.substring(0, 4) === 'http' ? (
                    <li className="line">
                      <a className="externalLink" href={note} target="_blank" rel="noopener noreferrer">
                        <i>Link</i>
                        <ArrowUpRight size={18} />
                      </a>
                    </li>
                  ) : (
                    <li className="line">{note}</li>
                  )
                )}
              </ul>
            </td>
          </tr>
        </span>
      ) : null}
    </>
  );
}
